import * as Game from "./Game";
import { io } from 'socket.io-client';

var socket = io("https://basebase.party:3000");
console.log(socket.active);


/* global game01 */
/*eslint no-restricted-globals: ["error", "game01"]*/


declare var game01 : Game.Game;
//export var game01 : Game.Game;

var game01 = new Game.Game("game", window.innerWidth, window.innerHeight);

game01.generateUI();


socket.emit("register", "donald trump");

socket.on('updateBuildingHealth', function(buildingToken: string, health: number){
    window.console.log("updateBuildingHealth: buildingtoken: "+ buildingToken+" health: "+health);
    //game01.updateBuildingHealth(buildingToken,health);
});

socket.on('loggedIn', function(){
    window.console.log("logged in");
    //game01.updateBuildingHealth(buildingToken,health);
});

socket.on('joinedGame', function(gameid: number){
    console.log("joinedToGame: "+gameid);
});

socket.on('createBuilding', function(idToken: string, buildingName: string ,position:number[] ,base_idToken: string, connections:string[]){
    window.console.log("createBuilding");
    game01.createBuilding(idToken, buildingName,position,base_idToken, connections);
});


/*
game01.createBuilding("token01","gugus1", [100,200], "bulding", []);

game01.createBuilding("token03","gugus3", [1500,1000], "bulding", []);
game01.createBuilding("token04","gugus4", [-200,-500], "bulding", ["token01"]);
game01.createBuilding("token02","gugus2", [300,305], "mill", ["token01", "token04", "token03"]);

setTimeout(function(){ game01.updateBuildingHealth("token01", 20); }, 3000);
setTimeout(function(){ game01.updateBuildingHealth("token03", 80); }, 5000);
setTimeout(function(){ game01.updateBuildingHealth("token04", 10); }, 13000);
*/