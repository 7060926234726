import Konva from 'konva';
export class OutputObject{
  game: any;
  x: number;
  y: number;
  type: string;
  path: number[][];
  animation:  any;
  output : any;



  constructor(game: any, startX: number, startY: number, type: string, path: number[][]) {
      this.game = game;
      this.x = startX;
      this.y = startY;
      this.type = type;
      this.path = path;
  }

  generateOutput(){
    this.output = new Konva.Circle({
        x: this.x, //- Math.ceil(Math.random() * 50) * (Math.round(Math.random()) ? 1 : -1),
        y: this.y, // - Math.ceil(Math.random() * 50) * (Math.round(Math.random()) ? 1 : -1),
        radius: 10,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 2,
    });

    this.output.path = [];



    var that = this;
    this.animation = new Konva.Animation(function(frame: any) {
        let target_x = that.path[0][0];
        let target_y = that.path[0][1];

        let faktorx: number = 1;
        let faktory: number = 1;

        let xdif: number;
        let ydif: number;

        let xdistance: number;
        let ydistance: number;


        if(target_x > that.x){
          xdif = +1;
          xdistance = target_x - that.x;
          if(that.output.x() >= target_x){
            that.animation.stop();
            that.output.destroy();
          }
        }

        if(target_x < that.x){
          xdif = -1
          xdistance = that.x - target_x;
          if(that.output.x() <= target_x){
            that.animation.stop();
            that.output.destroy();
          }
        }

        if(target_y > that.y){
          ydif = +1;
          ydistance = target_y - that.y;
          if(that.output.y() >= target_y){
            that.animation.stop();
            that.output.destroy();
          }
        }

        if(target_y < that.y){
          ydif = -1
          ydistance = that.y - target_y;
          if(that.output.y() <= target_y){
            that.animation.stop();
            that.output.destroy();
          }
        }
        faktory = ydistance / xdistance;

        /*
        console.log("-----------------------");
        console.log("x: "+that.x);
        console.log("y: "+that.y);
        console.log("Target x: "+target_x);
        console.log("Target y: "+target_y);
        console.log("Target x: "+target_x);
        console.log("Distance X: "+xdistance);
        console.log("Distance Y: "+ydistance);
        console.log("-----------------------");
        */

        that.output.move({x: xdif, y: ydif*faktory});

        // update stuff
    }, this.game.Layer03);


    this.game.Layer03.add(this.output);
  }
}