import Konva from 'konva';
import { OutputObject } from "./OutputObject";

export class Building{
    game: any;
    name: string;
    x: number;
    y: number;
    type: string;
    id: string;
    konvaGroup: any;
    health: number;
    updateHealthAnimation:  any;
    buildingDialog: any;
    connections: string[] = [];

    buildingTime: number;

    ticker: ReturnType<typeof setTimeout>;

    outputs: any[] = [];

    constructor(game: any, name: string, startX: number,startY: number, id: string, buildingType: string, connections?: string[]) {
        this.game = game;
        this.name = name;
        this.x = startX;
        this.y = startY;
        this.type = buildingType;
        this.id = id;
        this.connections = connections;
        this.health = 100;
        this.buildingTime = 5000;
        this.initBuilding();
    }

    initBuilding(){
        var that = this;
        if(this.type == "mill"){
            this.ticker = setTimeout(function(){
                that.generateOutput();
                that.initBuilding();
                }, this.buildingTime);
        }

    }

    generateOutput(){
        console.log("new output")
        
        
        if(typeof this.connections !== 'undefined'){
            if(this.connections[0] !== ""){
                console.log(this.connections);
                let nextBuilding = this.connections[Math.floor(Math.random() * this.connections.length)];
                let output = new OutputObject(this.game, this.x, this.y, "wood", [[this.game.getBuildingByID(nextBuilding).x, this.game.getBuildingByID(nextBuilding).y]]);
                output.generateOutput();
                output.animation.start();
                this.outputs.push(output)
            };
        }

        this.game.Layer03.draw();
    }

    generateBuildingDialog(){
            var baseDialog = new Konva.Group({
                x: this.x -250,
                y: this.y + 120,
                rotation: 0,
                id: "baseDialog"
            });
        
            var baseDialogStroke = new Konva.Rect({
                x: 0,
                y: 0,
                width: 500,
                height: 200,
                fill: 'white',
                stroke: 'black',
                strokeWidth: 2,
                id: "buildingDialogStroke"
            });

            var baseDialogTitle = new Konva.Text({
                x: 5,
                y: 5,
                text: this.name,
                fontSize: 12,
                fontFamily: 'Calibri',
                fill: 'green'
            });

            var baseDialogBT = new Konva.Text({
                x: 5,
                y: 25,
                text: "Buildingtime: "+this.buildingTime/1000+" s",
                fontSize: 12,
                fontFamily: 'Calibri',
                fill: 'green'
            });

            var baseDialogXY = new Konva.Text({
                x: 5,
                y: 40,
                text: "X: "+this.x+" Y: "+this.y,
                fontSize: 12,
                fontFamily: 'Calibri',
                fill: 'green'
            });
        
            var baseDialogHeaderStroke = new Konva.Rect({
                x: 0,
                y: 0,
                width: 500,
                height: 20,
                fill: 'white',
                stroke: 'black',
                strokeWidth: 2,
                id: "buildingDialogHeaderStroke"
            });
        
            var baseDialogHeaderClose = new Konva.Circle({
                x: 485,
                y: 10,
                radius: 5,
                fill: 'white',
                stroke: 'black',
                strokeWidth: 2,
                id: "buildingDialogHeaderClose"
              });

            baseDialogHeaderClose.on('mouseover', () => {
                baseDialogHeaderClose.fill("red");
                this.game.Layer01.draw();
            });

            baseDialogHeaderClose.on('mouseout', () => {
                baseDialogHeaderClose.fill("white");
                this.game.Layer01.draw();
            });

            baseDialogHeaderClose.on('click', () => {
                baseDialog.destroy();
                this.game.Layer01.draw();
            });
        
            baseDialog.add(baseDialogStroke);
            baseDialog.add(baseDialogHeaderStroke);
            baseDialog.add(baseDialogHeaderClose);
            baseDialog.add(baseDialogTitle);
            baseDialog.add(baseDialogBT);
            baseDialog.add(baseDialogXY);
            this.game.Layer01.add(baseDialog);
            this.game.Layer01.draw();
    }
    
    generateBuilding(){
        // temp colorpicker
        let backgroundcolor: string;

        if(this.type == "mill"){
            backgroundcolor = "brown";
        }else{
            backgroundcolor = "grey"
        }

        this.konvaGroup = new Konva.Group({
            x: this.x,
            y: this.y,
            rotation: 0,
            name: this.type,
            id: this.id
        });

        var circle = new Konva.Circle({
          x: 0,
          y: 0,
          radius: 70,
          fill: backgroundcolor,
          stroke: 'black',
          strokeWidth: 2
        });

        circle.on('click', () => {
            // show dialog
            this.generateBuildingDialog();
            //circle.dispatchEvent(new CustomEvent('showBuildingDialog', { bubbles: true, detail: { id: () => this.id } }))
        });

        var obox = new Konva.Rect({
            x: -70,
            y: 80,
            width: 140,
            height: 10,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 1
        });
        
        var ibox = new Konva.Rect({
            x: -69,
            y: 81,
            width: this.health * 138 / 100,
            height:8,
            fill: 'green',
            stroke: 'green',
            strokeWidth: 0,
            name: "ibox"
        });

        this.konvaGroup.add(circle);
        this.konvaGroup.add(obox);
        this.konvaGroup.add(ibox);

        this.updateHealthAnimation = new Konva.Animation(() =>{
            var iboxWidthTarget = this.health * 138 / 100;
            var ibox = this.konvaGroup.findOne(".ibox");

            var iboxWidth = ibox.width(); // (ibox.width() * 100) / (obox.width() - 2);
            if(iboxWidthTarget - 1 > iboxWidth){
                ibox.width(
                    ibox.width() +1
                );
            }else if(iboxWidthTarget + 1 < iboxWidth){
                ibox.width(
                    ibox.width() - 1
                );            
            }else{
                this.updateHealthAnimation.stop();
            }
        
            // update stuff
        }, this.konvaGroup);

        this.konvaGroup.name(this.type);

        return this.konvaGroup;
    }

    updateHealth(health: number){
        if(health != this.health){
            this.health = health;
            this.updateHealthAnimation.start();
        }
    }
}