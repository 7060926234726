import Konva from 'konva';
import * as Building from "./Building";

export class Game{
    Stage : any;

    UIStage : any;
    public UIStage_UI: any;
    public UIStage_Header_y: number;
    public UIStage_Header_x: number;
    public zoom: number;


    public Layer01 : any;
    public Layer02 : any;
    public Layer03 : any;
    Builings: Building.Building[] = [];

    Building01: any;
    Building02: any;


    constructor(container: string, width: number, height: number) {
        this.UIStage_Header_y = 55;
        this.UIStage_Header_x = width;
        this.zoom = 1.0;

        this.UIStage = new Konva.Stage({container: "gameUI", width: width, height: 55, draggable: false});
        this.UIStage_UI = new Konva.Layer();
        this.UIStage.add(this.UIStage_UI);

        this.Stage = new Konva.Stage({container: container, width: width, height: height - this.UIStage_Header_y, draggable: true});
        this.Layer01 = new Konva.Layer();
        this.Layer02 = new Konva.Layer();
        this.Layer03 = new Konva.Layer();
        this.Stage.add(this.Layer02);
        this.Stage.add(this.Layer01);
        this.Stage.add(this.Layer03);

        this.Stage.scaleX(this.zoom);
        this.Stage.scaleY(this.zoom);
        
        this.Stage.on("showBuildingDialog", () => {
            console.log("event");
        });

        this.Stage.on("wheel", (wheelEvent: any) => {
            if(Math.sign(wheelEvent.evt.deltaY) == 1){
                this.changeZoomIN();
            }else{
                this.changeZoomOUT();
            }
        });
    }

    changeZoomIN(){
        this.zoom = this.zoom - 0.1;
        this.Stage.scaleX(this.zoom);
        this.Stage.scaleY(this.zoom);
        this.Stage.draw();
    }

    changeZoomOUT(){
        this.zoom = this.zoom + 0.1;
        this.Stage.scaleX(this.zoom);
        this.Stage.scaleY(this.zoom);
        this.Stage.draw();
    }

    generateUI(){
        var header = new Konva.Group({
            x: 0,
            y: 0,
            rotation: 0,
            id: "header"
        });

        var baseDialogStroke = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.Stage.getAttr('width'),
            height: 50,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 2,
            id: "headerStroke"
        });

        header.add(baseDialogStroke);
        this.UIStage_UI.add(header);
        this.UIStage_UI.draw();
    }

    createBuilding(buildingToken: string, name: string, position:number[], buildingtype: string, connections:string[]){
        var building = new Building.Building(this, name, position[0],position[1],buildingToken, buildingtype ,connections);
        this.Builings.push(building);
        this.Layer01.add(building.generateBuilding());
        this.drawStreets();
        this.Layer01.draw();
    }

    getBuildingByID(id: string){
        return this.Builings.find(Building => Building.id === id);
    }

    updateBuildingHealth(buildingToken: string, health: number){
        // buildingToken
        this.getBuildingByID(buildingToken).updateHealth(health);
    }

    updateHealth(heath: number){
        this.getBuildingByID('base2').updateHealth(50);
    }

    drawStreets(){
        this.Builings.forEach((base: Building.Building)=>{
            console.log("Base:"+base.id);
            if(typeof base.connections !== 'undefined'){
                if(base.connections[0] !== ""){
                    base.connections.forEach((cbase:string) =>{
                        var cbaseObj = this.Stage.findOne("#"+cbase);
                        var connectionLine = new Konva.Line({
                            points: [base.x, base.y, cbaseObj.x(), cbaseObj.y()],
                            stroke: 'black',
                            strokeWidth: 5
                        });
                        console.log("Lines:"+connectionLine);
                        this.Layer02.add(connectionLine);
                        this.Layer02.draw();
                       // this.Layer01.add(connectionLine);
                        //this.Layer01.daw();
                    });
                }
            }
        });
    }
}